.cardFrame {
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    border: none;
    max-width: 370px;
    min-width: 370px;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
    margin: 12px 0;
    padding: 10px 20px;
/* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 1);
}

.apoios {
    display: flex;
    align-items: center;
    gap: 12px;
}
.title {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.miniatura {
    display: flex;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}
.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    max-height: 100vh;
    box-sizing: border-box;
}

.downBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: none;
    position: relative;
    height: 45px;
    border: 1px solid #ffa95d;
    border-radius: 8px;
    background-color: #fefefe;
    color: #000;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: bold;
}
.downBtn:hover {
    color: #FFF;
    background-color: #ff7300;
}

.fileButton {
    display: flex;
    flex:1;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: none;
    position: relative;
    height: 35px;
    border: 1px solid #0752e9;
    border-radius: 8px;
    background-color: #fefefe;
    color: #000;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: bold;
    width: 100%;
    margin-bottom: 15px;
}
.fileButton:hover {
    color: #FFF;
    background-color: #0752e9;
}